

.channels-chats-container {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.channels-chat {
    width: 350px;
    margin-right: 5px;
    background-color: #F5F5F5;
    border-radius: 20px 20px 0px 0px;
}

.channels-chat-footer {
    display: flex;
    flex-flow: row nowrap;
    /* background-color: #52002E; */
    overflow: visible;
}


.channels-chat-header {
    display: flex;
    flex-flow: row nowrap;
    padding: 14px 0px 10px 0px;
    justify-content: space-between;
    cursor: pointer;
    background-color: #F5F5F5;
    border-radius: 20px 20px 0px 0px;
}
    .channels-chat-title {
        font-family: 'trade-gothic';
        font-weight: 700;
        font-size: 22px;
        color: #555555;
        position: relative;
        margin-left: 20px;
    }
        .channels-chat-title-highlight {
            color: #84004C;
        }

    .channels-chat-statusConnection {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin: 3px 5px 8px 10px;
        position: absolute;
        top: 0px;
        left: 5px;
    }
        .channels-chat-disconnected { background-color: red; }
        .channels-chat-connecting { background-color: yellow; }
        .channels-chat-connected { background-color: #6AF0A0; }

    .channels-chat-tabs {
        display: flex;
        flex-flow: row nowrap;
        margin-right: 20px;
    }
        .channels-chat-tab {
            cursor: pointer;
            color: #555555;
            border-radius: 5px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
        }

        .channels-chat-tab-active {
            background-color: #B8006B;
            color: white;
        }

            .channels-chat-channelName-title {
                font-family: 'Montserrat';
                font-weight: 500;
                line-height: 0px;
                margin: 15px 10px 15px 10px;
            }

.channels-chat-body {
    height: 300px;
    width: 100%;
    background-color: #FBF9FF;
    background-color: #F5F5F5;
    overflow-y: scroll;
}

    .channles-message-hour {
            font-family: 'latobold';
            color: gray;
            font-size: 10px;
            margin-left: 5px;
        }

.channels-chat-input {
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    bottom: 0px;
}
    .channels-chat-nftbutton{
        display: flex;
        align-items: center;
        font-family: 'Montserrat';
        font-weight: 600;
        background-color: #84004C;
        border-radius: 7px;
        color: white;
        padding: 5px;
        font-size: 10px;
        margin-left: 10px;
        height: 28px;
    }

    .channels-chat-nftbutton > p {
        font-size: 13px;
        margin-bottom: 0px;
    }

    .channels-chat-textbox {
        border: 1px solid #3C1518;
        outline: none;
        resize: none;
        overflow: hidden;
        margin-right: 60px;
        margin-left: 10px;
        border: none;
        border-radius: 20px;
        font-size: 15px;
        line-height: 25px;
        font-family: monospace;
        background-color: #D9D9D9;
        padding-right: 15px;
        padding-left: 15px;
    }

    .channels-chat-send {
        margin-top: 2px;
        margin-right: 10px;
        cursor: pointer;
        position: absolute;
        bottom: 9px;
        right: 17px;
    }

.channels-hidden {
    display: none;
}

.channels-date-line{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 18px;
}

.channels-date-line-line{
    height: 1px;
    background: rgba(200,200,200);
    width: 100%;
}

.channels-date-line-day{
    font-family: "Montserrat";
    color: rgb(114,114,114);
    margin: 0;
    padding: 0;
    font-size: 12px;
}