.thumbs-wrapper {
  height: 15vh;
  display: flex;
  align-items: center;
}



.AutoCarousel > * .thumb > img {
  object-fit: cover;
  margin-bottom: 0px;
  
  height: 100px;
}

.AutoCarousel > * .thumb {
  
  height: 100px;
  object-fit: scale-down;
  border: 3px solid #FE702788;
  
}

.AutoCarousel > * > .carousel-slider > .control-arrow  {
  width: 15%;
}