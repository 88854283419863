.FullImageSliderContainer > .carousel-root {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: space-between;
}

.FullImageSliderContainer > * .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.FullImageSliderContainer > * .slide:first-child > div{
  margin-left: 25%;
}

.FullImageSliderContainer > * .slide:last-child > div{
  margin-right: 25%;
}

.FullImageSliderContainer > * > .carousel-slider > .control-arrow  {
  width: 10%;
}


.FullImageSliderContainer > * .thumb {
  width: 120px;
  max-width: 120px;
  height: 80px;
  object-fit: scale-down;
  border: 3px solid #333;
  margin-right: 20px;
}

.FullImageSliderContainer > * .thumb.selected {

  border: 3px solid #FE702788;  
}

.FullImageSliderContainer > * .thumb > img {
  object-fit: cover;
  margin-bottom: 0px;
  max-width: 120px;
  height: 80px;
}

.FullImageSliderContainer > * .thumbs-wrapper {
  height:  auto;
  margin-bottom: 0px;
}