.App {
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wrapperImageFit {
  object-fit: cover;
}
.imageFit {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
}

.no-decoration {
  text-decoration: none;
  color: black;
}

body {
  overflow-y: overlay;
}

.view {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
}

@media all and (min-width: 950px) {
  .App {
    
  }

  .view {
    min-height: calc(100vh - 100px); 
    padding-top: 100px;
  }
}

@media all and (max-width: 950px) {
  .App {
    
  }

  .view {
    min-height: calc(100vh - 70px); 
    padding-top: 70px;
  }
}