.login-errorMsg {
    color: red;
    font-family: 'montserrat-regular';
    font-size: 13px;
}

.auth-form-container {
    max-width: 410px !important;
}

.auth-main {
    /* background-image: linear-gradient(to bottom right, #1EFFBC, #20494F); */
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}


.auth-form-title {
    font-weight: 500;
    font-size:  35px;
    color: black;
    font-family: libre-bold;
    margin-bottom: 10px;
    text-align: center;
}
.auth-form-title-forgot {
    font-weight: 500;
    font-size:  25px;
    color: black;
    font-family: libre-bold;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
}
.auth-form-title2 {
    font-size:  15px;
    font-weight: 600;
    text-align: left;
}
.auth-form-title3 {
    font-size:  13px;
    text-align: left;
}

    .auth-form-form {
        max-width: 300px;
    }

    .auth-input {
        width: 100%;
        border: none;
        border-radius: 5px;
        border: 1px solid rgba(211,211,211, 0.5);
        font-size: 13px;
        line-height: 30px;
        margin-top: 15px;
        padding: 5px 5px 5px 15px;
        box-shadow: 0px 5px 5px rgba(211,211,211, 0.5);
    }
        .auth-input-error {
            border: 1px solid red;
        }

        .auth-input:focus{
            outline: 1px solid black;
        }

    .auth-acept-terms{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 20px;
    }

        .auth-check-label {
            font-size: 10px;
            text-align: right;
            margin-left: 10px;
            cursor: pointer;
        }

    ::placeholder { opacity: 0.5; }
    :-ms-input-placeholder { opacity: 0.5; }
    ::-ms-input-placeholder { opacity: 0.5; }

    .auth-submit {
        margin-top: 20px;
        margin-bottom: 5px;
    }
        .auth-button {
            width: 100%;
            background-color: #84004C;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            padding: 5px 0px 5px 0px;
            color: white;
            border: none;
            text-decoration: none;
        }

        .auth-button-disabled {
            background-color: gray;
        }

        .auth-loading-gif {
            width: 23px;
        }


    .auth-resetpassword-container {
        font-size: 12px;
        margin-top: 20px;
        cursor: pointer;
        text-align: right;
    }

    .auth-signUp {
        font-size: 14px;
        font-weight: 400;
        margin-top: 20px;
        cursor: pointer;
        text-align: right;
        text-decoration: none !important;
        color: black;
    }
        .auth-signUp-highlight {
            color: #84004C;
            font-weight: 600;
        }



.auth-hidden {
    display: none;
}


.auth-messageError {
    font-weight: 500;
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: right;
}

.auth-messageInfo {
    font-weight: 500;
    color: black;
    font-size: 12px;
    margin-top: 5px;
    text-align: right;
}

.auth-verificaction-links-bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

    .auth-verificaction-goback {
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
        color: #C42021;
        cursor: pointer;
    }

    .auth-verification-resendcode {
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        text-decoration: none !important;
        color: black;
    }

    .auth-verification-resendcode-sended {
        text-decoration: none;
    }
        .auth-verification-resendcode > a {
            cursor: pointer;
        }

.auth-succes-image-container{
    background-color: #6A4BFF;
    border-radius: 100px;
    display: inline-block;
    padding: 15px;
}
    .auth-succes-image {
        width: 40px;
    }

.auth-success-title {
    font-weight: 800;
    font-size:  25px;
    margin-top: 50px;
}

.auth-success-title2 {
    font-weight: 600;
    font-size:  15px;
    margin-top: 10px;
    margin-bottom: 40px;
}